<template>
  <Layout>
    <PageHeader :items="items" />
    <!--FILTER START------>
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div
            v-if="
              this.$storageData.login_type == 1 ||
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
            "
            class="col-md-2"
            style="width:11%"
          >
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getAllCompanies()"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Company List-->
          <div
            v-if="
              this.$storageData.login_type == 1 ||
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Company</label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              :show-labels="false"
              label="companyName"
              track-by="companyID"
              @input="getRestaurantBrandsByCompanyID()"
            ></multiselect>
          </div>
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div
            v-if="
              this.$storageData.login_type == 1 ||
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              :show-labels="false"
              label="name"
              track-by="restID"
              @input="getRestaurantCities()"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Cities List-->
          <div
            v-if="
              this.$storageData.login_type == 1 ||
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
            class="col-md-2"
            style="width:12%"
          >
            <label>City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="readRestaurantBranchData()"
            ></multiselect>
          </div>
          <!--END Cities List-->
          <!-- Branch List-->
          <div
            v-if="
              this.$storageData.login_type == 1 ||
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 9 ||
                this.$storageData.profile.empTypeID == 11
            "
            class="col-md-2"
            style="width:16%"
          >
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              label="branchName"
              track-by="restBranchID"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Date range -->
          <div class="col-md-2" style="width:20%">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              append-to-body
              confirm
              format="DD MMM Y"
              lang="en"
              range
              @input="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END-->
          <!-- Order Type -->
          <!-- <div class="col-md-2"  style="width:18%">   
                  <label>Order Type </label>
                  <multiselect v-model="orderType" :options="orderTypeArr" @input="onchangeBranch();"></multiselect>
          </div> -->
          <!-- Order Type END-->
          <!-- Apply filter -->
          <div class="col-md-2" style="width:auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->
          <!-- Clear filter -->
          <div class="col-md-2" style="width:10%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div
            v-if="$storageData.profile.empTypeID == 2 || $storageData.profile.empTypeID == 11"
          >
            <RestaurantOwnerValidation
              :active_tab="this.activeTab"
              :city="this.city"
              :company="
                this.company != '' ? this.company : this.$storageData.profile.companyID
              "
              :date="this.daterange"
              :employee_type="this.$storageData.profile.empTypeID"
              :owner_id="this.$storageData.profile.pid"
              :report_type="this.reportType"
              :restaurant_branch="
                this.restBranchID
                  ? this.restBranchID
                  : this.$storageData.profile.restBranchID
              "
              :restaurant_id="
                this.brandData != ''
                  ? this.brandData.restID
                  : this.$storageData.profile.restID
              "
            />
          </div>
          <div
            v-if="visibleDataMessage != ''"
            class="text-left text-danger my-2"
          >
            <strong>{{ visibleDataMessage }}</strong>
          </div>
          <div v-if="isLoading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
            v-model="productMixTab"
            content-class="p-3 text-muted"
            justified
            nav-class="nav-tabs-custom"
          >
            <b-tab active @click="getProductMixReport()">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  >Product Mix Overview</span
                >
              </template>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Selling Products By Value</h6>
                      <div v-if="!topSellingProductsByValueSeries.length == 0">
                        <apexchart
                          :options="
                            topSellingProducts.topSellingProductsByValue
                              .chartOptions
                          "
                          :series="
                            topSellingProducts.topSellingProductsByValue.series
                          "
                          class="apex-charts"
                          dir="ltr"
                          height="350"
                          type="bar"
                        ></apexchart>
                      </div>
                      <div v-else style="height: 320px"></div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Selling Products By Volume</h6>
                      <div v-if="!topSellingProductsByVolumeSeries.length == 0">
                        <apexchart
                          :options="
                            topSellingProducts.topSellingProductsByVolume
                              .chartOptions
                          "
                          :series="
                            topSellingProducts.topSellingProductsByVolume.series
                          "
                          class="apex-charts"
                          dir="ltr"
                          height="350"
                          type="bar"
                        ></apexchart>
                      </div>
                      <div v-else style="height: 320px"></div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Selling Products By Category</h6>
                      <div
                        v-if="!topSellingProductsByCategorySeries.length == 0"
                      >
                        <apexchart
                          :options="
                            topSellingProducts.topSellingProductsByCategory
                              .chartOptions
                          "
                          :series="
                            topSellingProducts.topSellingProductsByCategory
                              .series
                          "
                          class="apex-charts"
                          dir="ltr"
                          height="350"
                          type="bar"
                        ></apexchart>
                      </div>
                      <div v-else style="height: 320px"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!----START OF PRODUCT BY VALUE---->
            </b-tab>
            <b-tab @click="getProductMixReportByValue(1)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">List By Value</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                      <div class="btn-group" role="group">
                        <!-- <download-excel
                            type="button" 
                            class="btn btn-themeBrown"
                            :data="tableData">
                            Excel
                          </download-excel> -->
                        <button
                          v-if="this.$storageData.login_type == 1"
                          class="btn btn-themeBrown"
                          type="button"
                          @click="downloadSample()"
                        >
                          Excel
                        </button>
                        <!-- <button type="button" class="btn btn-themeBrown">Excel</button> 
                          <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->

                        <button
                          v-if="
                            $storageData.profile.empTypeID == 2 ||
                              $storageData.profile.empTypeID == 11
                          "
                          class="btn btn-themeBrown"
                          type="button"
                          @click="validateUser(1)"
                        >
                          Download Excel
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show &nbsp;&nbsp;
                          <b-form-select
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            style="margin-left:5px; margin-right:5px"
                          ></b-form-select
                          >&nbsp;&nbsp;&nbsp; entries
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-4 row">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                      >
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                            v-model="filter"
                            class="form-control form-control-sm ms-2"
                            placeholder="Search..."
                            type="search"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      :busy="isReportTableBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :items="tableData"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      bordered
                      fixed
                      fixed-header
                      foot-clone
                      head-variant="light"
                      outlined
                      responsive="sm"
                      striped
                      @filtered="onFiltered"
                    >
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template v-slot:head(totalItemPrice)>
                        <p style="margin:0;">Sales</p>
                      </template>
                      <template v-slot:head(totalItemTax)>
                        <p style="margin:0;">GST</p>
                      </template>
                      <template v-slot:head(subsidy)>
                        <p style="margin:0;">Meal Plan</p>
                      </template>
                      <template v-slot:head(totalItemQuantity)>
                        <p style="margin:0;">Quantity</p>
                      </template>
                      <template v-slot:head(totalRevenue)>
                        <p style="margin:0;">Total</p>
                      </template>
                      <!-- FOOTER -->
                      <template v-slot:foot(itemName)>
                        <span>Total: </span>
                      </template>
                      <template v-slot:foot(restaurantName)>
                        <span></span>
                      </template>
                      <template v-slot:foot(foodType)>
                        <span></span>
                      </template>
                      <template v-slot:foot(category)>
                        <span></span>
                      </template>
                      <template v-slot:foot(totalRevenue)>
                        <p style="margin:0;">
                          {{ overviewByValue.sumTotalRevenue }}
                        </p>
                      </template>
                      <template v-slot:foot(totalItemQuantity)>
                        <p style="margin:0;">
                          {{ overviewByValue.sumTotalItemQuantity }}
                        </p>
                      </template>
                      <template v-slot:foot(totalItemTax)>
                        <p style="margin:0;">
                          {{ overviewByValue.sumTotalItemTax }}
                        </p>
                      </template>
                      <template v-slot:foot(totalItemPrice)>
                        <p style="margin:0;">
                          {{ overviewByValue.sumTotalItemPrice }}
                        </p>
                      </template>
                      <template v-slot:foot(subsidy)>
                        <!-- <p style="margin:0;">{{ overviewByValue.sumSubsidy }}</p> -->
                        <span></span>
                      </template>
                      <template v-slot:foot(prodVoucher)>
                        <p style="margin:0;">
                          {{ overviewByValue.sumProdVoucher }}
                        </p>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-end"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="rows"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!----END OF PRODUCT BY VALUE---->
            </b-tab>
            <b-tab @click="getProductMixReportByValue(2)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-envelope"></i>
                </span>
                <span class="d-none d-sm-inline-block">List By Volume</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div
                          class="col-sm-12 col-md-4"
                          style="margin-bottom:15px;"
                        >
                          <div class="btn-group" role="group">
                            <button
                              v-if="this.$storageData.login_type == 1"
                              class="btn btn-themeBrown"
                              type="button"
                              @click="downloadSample()"
                            >
                              Excel
                            </button>
                            <button
                              v-if="
                                $storageData.profile.empTypeID == 2 ||
                                  $storageData.profile.empTypeID == 11
                              "
                              class="btn btn-themeBrown"
                              type="button"
                              @click="validateUser(2)"
                            >
                              Download Excel
                            </button>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-4">
                          <div
                            id="tickets-table_length"
                            class="dataTables_length"
                          >
                            <label class="d-inline-flex align-items-center">
                              Show &nbsp;&nbsp;
                              <b-form-select
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                style="margin-left:5px; margin-right:5px"
                              ></b-form-select
                              >&nbsp;&nbsp;&nbsp; entries
                            </label>
                          </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-4 row">
                          <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                          >
                            <label class="d-inline-flex align-items-center">
                              Search:
                              <b-form-input
                                v-model="filter"
                                class="form-control form-control-sm ms-2"
                                placeholder="Search..."
                                type="search"
                              ></b-form-input>
                            </label>
                          </div>
                        </div>
                        <!-- End search -->
                      </div>
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                          :busy="isReportTableBusy"
                          :current-page="currentPage"
                          :fields="fields"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          :items="tableData"
                          :per-page="perPage"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          bordered
                          fixed-header
                          foot-clone
                          hover
                          outlined
                          responsive="sm"
                          striped
                          @filtered="onFiltered"
                        >
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>

                          <template v-slot:head(totalItemPrice)>
                            <p style="margin:0;">Sales</p>
                          </template>

                          <template v-slot:head(totalItemTax)>
                            <p style="margin:0;">GST</p>
                          </template>
                          <template v-slot:head(subsidy)>
                            <p style="margin:0;">Meal Plan</p>
                          </template>

                          <template v-slot:head(totalItemQuantity)>
                            <p style="margin:0;">Quantity</p>
                          </template>
                          <template v-slot:head(totalRevenue)>
                            <p style="margin:0;">Total</p>
                          </template>

                          <!-- FOOTER -->
                          <!-- FOOTER -->
                          <template v-slot:foot(itemName)>
                            <span>Total: </span>
                          </template>
                          <template v-slot:foot(restaurantName)>
                            <span></span>
                          </template>
                          <template v-slot:foot(foodType)>
                            <span></span>
                          </template>
                          <template v-slot:foot(category)>
                            <span></span>
                          </template>
                          <template v-slot:foot(totalRevenue)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalRevenue }}
                            </p>
                          </template>
                          <template v-slot:foot(totalItemQuantity)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalItemQuantity }}
                            </p>
                          </template>
                          <template v-slot:foot(totalItemTax)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalItemTax }}
                            </p>
                          </template>
                          <template v-slot:foot(totalItemPrice)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalItemPrice }}
                            </p>
                          </template>
                          <template v-slot:foot(subsidy)>
                            <!-- <p style="margin:0;">{{ overviewByValue.sumSubsidy }}</p> -->
                            <span></span>
                          </template>
                          <template v-slot:foot(prodVoucher)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumProdVoucher }}
                            </p>
                          </template>
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage"
                                :per-page="perPage"
                                :total-rows="rows"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab @click="getProductMixReportByValue(3)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-cog"></i>
                </span>
                <span class="d-none d-sm-inline-block">List By Category</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row mt-4">
                        <div
                          class="col-sm-12 col-md-4"
                          style="margin-bottom:15px;"
                        >
                          <div class="btn-group" role="group">
                            <!-- <download-excel
              type="button" 
              class="btn btn-themeBrown"
              :data="tableData">
              Excel
            </download-excel> -->
                            <button
                              v-if="this.$storageData.login_type == 1"
                              class="btn btn-themeBrown"
                              type="button"
                              @click="downloadSample()"
                            >
                              Excel
                            </button>
                            <button
                              v-if="
                                $storageData.profile.empTypeID == 2 ||
                                  $storageData.profile.empTypeID == 11
                              "
                              class="btn btn-themeBrown"
                              type="button"
                              @click="validateUser(3)"
                            >
                              Download Excel
                            </button>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-4">
                          <div
                            id="tickets-table_length"
                            class="dataTables_length"
                          >
                            <label class="d-inline-flex align-items-center">
                              Show &nbsp;&nbsp;
                              <b-form-select
                                v-model="perPage2"
                                :options="pageOptions"
                                size="sm"
                                style="margin-left:5px; margin-right:5px"
                              ></b-form-select
                              >&nbsp;&nbsp;&nbsp; entries
                            </label>
                          </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-4 row">
                          <div
                            id="tickets-table_filter"
                            class="dataTables_filter text-md-end"
                          >
                            <label class="d-inline-flex align-items-center">
                              Search:
                              <b-form-input
                                v-model="filter"
                                class="form-control form-control-sm ms-2"
                                placeholder="Search..."
                                type="search"
                              ></b-form-input>
                            </label>
                          </div>
                        </div>
                        <!-- End search -->
                      </div>
                      <!-- Table -->
                      <div class="table-responsive mb-0">
                        <b-table
                          :busy="isReportTableBusy"
                          :current-page="currentPage2"
                          :fields="fields2"
                          :filter="filter2"
                          :filter-included-fields="filterOn2"
                          :items="tableData2"
                          :per-page="perPage2"
                          :sort-by.sync="sortBy2"
                          :sort-desc.sync="sortDesc2"
                          bordered
                          fixed-header
                          foot-clone
                          hover
                          outlined
                          responsive="sm"
                          striped
                          @filtered="onFiltered2"
                        >
                          <template #table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>

                          <template v-slot:cell(category)="data">
                            <span>{{
                              data.item.category == "" ||
                              data.item.category == null
                                ? "Others"
                                : data.item.category
                            }}</span>
                          </template>

                          <template v-slot:head(totalItemQuantity)>
                            <p style="margin:0;">Orders</p>
                          </template>

                          <template v-slot:head(totalItemPrice)>
                            <p style="margin:0;">Sales</p>
                          </template>

                          <template v-slot:head(totalItemTax)>
                            <p style="margin:0;">GST</p>
                          </template>

                          <template v-slot:head(totalRevenue)>
                            <p style="margin:0;">Total</p>
                          </template>

                          <!-- FOOTER -->
                          <!-- FOOTER -->
                          <template v-slot:foot(category)>
                            <span>Total: </span>
                          </template>
                          <template v-slot:foot(totalItemQuantity)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalItemQuantity }}
                            </p>
                          </template>
                          <template v-slot:foot(totalItemPrice)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalItemPrice }}
                            </p>
                          </template>

                          <template v-slot:foot(totalItemTax)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalItemTax }}
                            </p>
                          </template>
                          <template v-slot:foot(totalRevenue)>
                            <p style="margin:0;">
                              {{ overviewByValue.sumTotalRevenue }}
                            </p>
                          </template>
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                          >
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <b-pagination
                                v-model="currentPage2"
                                :per-page="perPage2"
                                :total-rows="rows2"
                              ></b-pagination>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import RestaurantOwnerValidation from "@/components/RestaurantOwnerValidation.vue";

export default {
  page: {
    title: "Product Mix Report",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    RestaurantOwnerValidation,
    Layout,
    PageHeader,
    Multiselect,
    DatePicker
  },
  data() {
    return {
      productMixTab: 0,
      isReportTableBusy: false,
      tableData: [],
      tableData2: [],
      visibleDataMessage: "",
      title: "Product Mix Report",
      items: [
        {
          text: "View"
        },
        {
          text: "Product Mix Report",
          active: true
        }
      ],
      restaurants: [],
      branches: [],
      cityArr: [],
      city: "",
      activeTab: 1,
      topSellingProducts: [],
      daterange: [],
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      restBranchID: this.$storageData.profile.restBranchID,
      reportType: "product_mix",
      // restaurantID:(sessionStorage.getItem('restID')>0)? [{'restID':sessionStorage.getItem('restID'),'name':sessionStorage.getItem('restName')}] : "",
      // branchID:(sessionStorage.getItem('branchID')>0) ? [{'restBranchID':sessionStorage.getItem('branchID'),'branchName':sessionStorage.getItem('branchName')}] : "",
      restaurantID: "",
      branchID: "",
      companyArr: [],
      companyID: 0,
      overviewByValue: [],
      overviewByCategory: [],

      // datatable 1 & 2 ///////
      sortBy: "age",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "itemName",
          sortable: true
        },
        {
          key: "restaurantName",
          sortable: true
        },
        {
          key: "foodType",
          sortable: true
        },
        // {
        //   key: "category",
        //   sortable: true,
        // },
        {
          key: "totalItemQuantity",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalItemPrice",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "subsidy",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "prodVoucher",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalItemTax",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalRevenue",
          sortable: true,
          tdClass: "align-right"
        }
      ],
      ////////// datatable 3 category //////////////////

      // datatable 1 & 2 ///////
      sortBy2: "age",
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter2: null,
      filterOn2: [],
      sortDesc2: false,
      fields2: [
        {
          key: "category",
          sortable: true
        },
        {
          key: "totalItemQuantity",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalItemPrice",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalItemTax",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalRevenue",
          sortable: true,
          tdClass: "align-right"
        }
      ],
      topSellingProductsByValueSeries: [],
      topSellingProductsByVolumeSeries: [],
      topSellingProductsByCategorySeries: [],
      isLoading: 0
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    rows2() {
      return this.tableData2.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.readRestaurantMenu();
    // this.getRestaurantList();

    if (
      this.$storageData.login_type == 1 ||
      this.$storageData.profile.empTypeID == 9
    ) {
      this.getAllCountry();
    } else if (
      this.$storageData.login_type == 2 ||
      this.$storageData.profile.empTypeID == 2 ||
      this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    } else if (this.$storageData.profile.empTypeID == 9) {
      this.getRestaurantBrands();
    }
    // else{
    //      this.getProductMixReport();
    // }
    // this.getProductMixReport();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then(response => {
        this.countryArr = response.data.data;
        // this.country = (this.countryArr) ? this.countryArr[0] : "";
        // this.getAllCities();
        if (
          this.$storageData.login_type == 1 ||
          this.$storageData.login_type == 2 ||
          this.$storageData.profile.empTypeID == 9
        ) {
          // super admin
          // this.getRestaurantBrands();
          this.getAllCompanies();
        }

        if (
          this.$storageData.login_type == 2 ||
          this.$storageData.profile.empTypeID == 11
        ) {
          // food company login
          this.getAllCompanies();
          this.getRestaurantBrandsByCompanyID();
        } else {
          this.readRestaurantBranchData();
        }
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.cityArr = response.data.data;
          //this.city = (this.cityArr) ? this.cityArr[0] : "";
          if (this.$storageData.login_type == 1) {
            // super admin
            // this.getRestaurantBrands();
            this.getAllCompanies();
          }

          if (
            this.$storageData.login_type == 2 ||
            this.$storageData.profile.empTypeID == 11
          ) {
            // food company login
            this.getAllCompanies();
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },

    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
          city: this.$storageData.profile.city
        })
        .then(result => {
          this.brandArr = result.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantBrandsByCompanyID() {
      this.brandData = [];
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {
          restID: this.$storageData.profile.restID
        })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getAllCompanies();
          // this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      if (
        this.$storageData.profile.city != "" &&
        localStorage.getItem("city") != undefined
      ) {
        this.cityArr = [{ city: this.$storageData.profile.city }];
        this.city = this.cityArr ? this.cityArr[0] : "";
        this.getRestaurantBrands();
      } else {
        var restID = this.brandData.restID;
        if (
          this.$storageData.login_type == 2 ||
          this.$storageData.profile.empTypeID == 11
        ) {
          restID = this.$storageData.profile.restID;
        }
        this.axios
          .post(this.$loggedRole+"/getRestaurantCities", {
            restID: restID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            country: this.country ? this.country.country : ""
          })
          .then(response => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            this.readRestaurantBranchData();
          });
      }
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", {
          companyID: companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.companyArr = response.data.data;
          if (
            this.$storageData.login_type == 2 ||
            this.$storageData.profile.empTypeID == 11
          ) {
            this.companyID = this.companyArr ? this.companyArr[0] : "";
            this.getRestaurantBrandsByCompanyID();
          }
          // this.branchID = (this.branches) ? this.branches[0] :"";
          // this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          // this.getProductMixReport();
        });
    },
    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.branches = response.data.data;
          // this.branchID = (this.branches) ? this.branches[0] :"";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          // this.getProductMixReport();
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();
      //  if(this.restaurantID.restID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('restID',this.restaurantID.restID);
      //    sessionStorage.setItem('restName',this.restaurantID.name);
      // }
      // if(this.branchID.restBranchID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('branchID',this.branchID.restBranchID);
      //    sessionStorage.setItem('branchName',this.branchID.branchName);
      // }
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      // this.getProductMixReport();
    },

    downloadSample() {
      let excelData = [];
      if (this.tableData.length > 0) {
        excelData = this.tableData;
      }
      if (this.tableData2.length > 0) {
        excelData = this.tableData2;
      }
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: excelData
          },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel"
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },
    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.getProductMixReport();
    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      if (this.productMixTab == 0) {
        this.getProductMixReport();
      } else {
        this.getProductMixReportByValue(this.productMixTab);
      }

      // this.getProductMixReportByCategory();
    },

    // changes done by mohammed on 10-12-2021
    getProductMixReport() {
      this.isLoading = 1;
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/v2/productMixReport", {
          restID:
            this.brandData != "" && this.brandData != null
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID:
            this.branchID != "" && this.branchID != null
              ? this.branchID.restBranchID
              : this.$storageData.profile.restBranchID, //this.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          daterange: this.daterange,
          companyID: companyID,
          loginTypeID: this.$storageData.login_type
        })
        .then(result => {
          this.isLoading = 0;
          this.visibleDataMessage = result.data.visibleDataMessage;
          this.topSellingProducts = result.data.data;
          this.topSellingProductsByValueSeries =
            result.data.data.topSellingProductsByValue.series[0].data;
          this.topSellingProductsByVolumeSeries =
            result.data.data.topSellingProductsByVolume.series[0].data;
          this.topSellingProductsByCategorySeries =
            result.data.data.topSellingProductsByCategory.series[0].data;
        })
        .catch(error => {
          this.isLoading = 0;
          this.visibleDataMessage = "";
          alert(error.response.data.message);
        });
    },
    // applyFilter(){
    //   this.readRestaurantMenu();
    // }

    // changes done by mohammed on 10-12-2021
    getProductMixReportByValue(flag) {
      this.isReportTableBusy = true;
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/v2/productMixReportByValue",
          {
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID:
              this.branchID != "" && this.branchID != null
                ? this.branchID.restBranchID
                : this.$storageData.profile.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            daterange: this.daterange,
            companyID: companyID,
            flag: flag
          } //this.branchID.restBranchID}
        )
        .then(response => {
          this.isReportTableBusy = false;
          this.visibleDataMessage = response.data.visibleDataMessage;
          if (flag === 3) {
            this.tableData = [];
            this.tableData2 = response.data.data;
          } else {
            this.tableData2 = [];
            this.tableData = response.data.data;
          }
          this.overviewByValue = response.data.overviewByValue;
        })
        .catch(error => {
          this.isReportTableBusy = false;
          this.visibleDataMessage = "";
          alert(error.response.data.message);
        });
    },
    validateUser(tab) {
      if (this.city.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select city!"
        });
      } else if (
        this.$storageData.profile.empTypeID == "" ||
        this.$storageData.profile.empTypeID == 0
      ) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Unable to determine user role!"
        });
      } else if (this.$storageData.profile.pid == "" || this.$storageData.profile.pid == 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Invalid user found!"
        });
      } else if (this.restBranchID.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Restaurant branch not found!"
        });
      } else if (this.brandData.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Restaurant brand not found!"
        });
      } else if (this.daterange.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select date!"
        });
      } else {
        this.activeTab = tab;
        this.$bvModal.show("two-factor-validation");
      }
    },
    getProductMixReportByCategory() {
      this.isReportTableBusy = true;
      var companyID = this.companyID;
      if (
        this.$storageData.login_type == 2 ||
        this.$storageData.profile.empTypeID == 11
      ) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getProductMixReportByCategory",
          {
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID: this.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            daterange: this.daterange,
            companyID: companyID
          } //this.branchID.restBranchID}
        )
        .then(response => {
          this.isReportTableBusy = false;

          this.tableData2 = response.data.data;
          this.overviewByCategory = response.data.overviewByCategory;
          //console.log((response));
        });
    }
  },
  middleware: "authentication"
};
</script>
<style lang="scss" scoped>
/** or use this class: table thead th.bTableThStyle { ... } */
.table .bTableThStyle {
  max-width: 12rem !important;
  text-overflow: ellipsis !important;
  background: #000;
}
</style>
